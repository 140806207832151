<template>
  <div>
    <section id="xanax666" class="text-center">
      <h1>
        xanax<span>666</span>
        <small> mushi mushi, you need a CS:GO cheat? </small>
      </h1>

      <img src="@/assets/loli.png" alt="" srcset="">
    </section>

    <div class="container">
      <section id="features">
        <h1>
          features
          <small> mushi mushi, know our qualities</small>
        </h1>

        <div class="row">
          <div class="col-sm-12 col-md-8">
            <div class="feature perspective-left">
              <div class="feature-info">
                <h2>
                  Legitbot
                  <small>
                    With weapon based configs, backtracking, and our triggerbot
                  </small>
                </h2>
              </div>

              <img
                src="https://cdn.discordapp.com/attachments/926175285605437470/927350051284606976/unknown.png"
                alt="xanax666 aimbot"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4"></div>

          <div class="col-sm-12 col-md-8">
            <div class="feature perspective-right">
              <div class="feature-info">
                <h2>
                  Ragebot
                  <small>
                    Feel like messing around? We have many features for that.
                  </small>
                </h2>
              </div>

              <img
                src="https://cdn.discordapp.com/attachments/926175285605437470/927352546731884544/unknown.png"
                alt="xanax666 legitbot"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-8">
            <div class="feature perspective-left">
              <div class="feature-info">
                <h2>
                  Improvements
                  <small>
                    Use exploits like fakelag, legit AA, rage AA and others.
                  </small>
                </h2>
              </div>

              <img
                src="https://cdn.discordapp.com/attachments/926175285605437470/927350279001768026/unknown.png"
                alt="xanax666 misc"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4"></div>

          <div class="col-sm-12 col-md-8">
            <div class="feature perspective-right">
              <div class="feature-info">
                <h2>
                  Visuals
                  <small>
                    From full assistance through walls, or slight help gathering information.
                  </small>
                </h2>
              </div>

              <img
                src="https://cdn.discordapp.com/attachments/926175285605437470/927350133434253352/unknown.png"
                alt="xanax666 legitbot"
              />
            </div>
          </div>
        </div>
      </section>
    </div>

    <section id="review">
      <div class="container">
        <h1>
          review
          <small> xanax v2 review by <a class="a_link" href="https://www.youtube.com/c/missfkmiss666/">miss</a></small>
        </h1>

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/iZOL5MjxUuA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>

    <section id="gameplay">
      <div class="container">
        <h1>
          gameplay
          <small> legit hacking gameplay by <a class="a_link" href="https://www.youtube.com/c/missfkmiss666/">miss</a></small>
        </h1>

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/j0NqRmD20Bs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped>
  #xanax666 {
    clip-path: ellipse(189% 100% at 130.62% 0%);
    background-color: #101010;
    padding: 105px 0 185px;
  }

  #xanax666 img {
    z-index: -1;
    width: 500px;
    float: right;
    position: sticky;
    width: 25%;
    bottom: 50px;
    animation: 1.5s infinite alternate kick-effect-i;
  }

  #xanax666 h1 {
    color: var(--secondary-fore-color);
    font-size: 85px;
    font-weight: 800;
  }

  #xanax666 span {
    color: var(--text-color);
  }

  #xanax666 small {
    font-size: 35px;
    font-weight: 500;
  }

  #features {
    padding: 0 0 100px;
  }

  .feature img {
    box-shadow: 0 0 10px 5px #101010;
    width: 95%;
    margin: 0.5em;
    border-radius: 12px;
  }

  .feature h2 {
    text-align: start !important;
  }

  .feature-info {
    background: #0e0e0e;
    padding: 0 20px;
    width: 90%;
    margin: 40px 0 25px;
    border-radius: 8px;
  }

  .feature small {
    padding: 0.7em 0;
    line-height: 22px;
  }

  .feature {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .perspective-left {
    transform: perspective(1000px) rotateY(10deg);
  }

  .perspective-right {
    transform: perspective(1000px) rotateY(-10deg) !important;
  }

  #review {
    /* -webkit-clip-path: ellipse(189% 100% at 130.62% 0%);
    clip-path: ellipse(189% 100% at 0% 130.62%); */
    /* padding: 300px 0 100px; */
    background-color: #101010;
  }

  #review, #gameplay {
    padding: 50px 0 70px;
  }

  #review iframe, #gameplay iframe {
    border-radius: 10px;
    box-shadow: 0 0 10px 5px #101010;
  }
</style>
